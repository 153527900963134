
import { computed, defineComponent, onMounted, ref, SetupContext, } from 'vue';
import { IPurpose, IUser } from '@/types/Interfaces';
import usePurpose from '@/types/Purpose';
import useMasterData from '@/types/MasterData';


import PurposeEditForm from './PurposeEditForm.vue';
import useAuth from '@/types/Auth';
import useFlashMessage from '@/types/FlashMessage';

export default defineComponent({
  components: {
    PurposeEditForm
  },
  setup(_, context: SetupContext) {
    // const headers = g
    const {
      setMessage  
    } = useFlashMessage();

    const {
      data,
      editingItem,
      onChangeDisplay
    } = useMasterData();

    const {
      fetchUsers
    } = useAuth()
    
    const {
      fetchPurposes,
      deletePurpose
    } = usePurpose();
    
    const userList = ref<IUser[]>([]);
    onMounted(async () => {
      data.value = await fetchPurposes(); 
      console.log(data.value) 
      userList.value = await fetchUsers();
    })
    
    const addTemplate: IPurpose = {
      name: '',
      sort: 0,
      bgcolor: '',
      fontcolor: '',
      approved_by_id: 0,
      display: false,
    }
    const onUpdate = async () => {
      editingItem.value = null;
      data.value = await fetchPurposes();
      setMessage({ type: 'success', message: '更新しました。' })
    }
    const onDelete = async (id: number) => {
      const d = await deletePurpose(id);
      data.value = await fetchPurposes();
      setMessage({ type: 'success', message: '削除しました。' })
    
      return d;
    }
    return {
      data,
      userList,
      editingItem,
      addTemplate,
      onChangeDisplay,
      onDelete,
      onUpdate,
    }
  }
})
