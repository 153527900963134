
import { computed, defineComponent, onMounted, PropType, ref, SetupContext, } from 'vue';
import { IProposalStatus, IPurpose, IUser } from '@/types/Interfaces';
import { cloneDeep, create, update } from 'lodash';
import usePurpose from '@/types/Purpose'
import { formatRelative } from 'date-fns';
import { validate, required, isNumber, isColor } from '@/mixins/Validators';
import SelectBox from '@/components/SelectBox.vue';

export default defineComponent({
  components: {
    SelectBox
  },
  props: {
    modelValue: {
      type: Object as () => IProposalStatus
    },
    userList: {
      type: Object as () => IUser[]
    }
  },
  setup(props: any, context: SetupContext) {
    
    const data = ref<IPurpose | null>(null);
    const {
      createPurpose,
      updatePurpose
    } = usePurpose()

    
    const errors = ref({
      name: '',
      sort: '',
      bgcolor: '',
      fontcolor: '',
      approved_by_id: ''
    })
    const validators = {
      name: [
        required
      ],
      sort: [
        required,
        isNumber
      ],
      bgcolor: [
        required,
        isColor
      ],
      fontcolor: [
        required,
        isColor
      ],
      approved_by_id: [
        required,
      ],
   
    }
    
    const onClose = () => {
      context.emit('close');  
    };
    const onUpdate = async () => {
      // do some async work
    
      if (data.value == null) return;
      console.log(data)
      if (!validate(data, validators, errors)) return;
      let r;
      try {
        if (data.value.id) {
          r = await updatePurpose(data.value.id, data.value)
        } else {
          r = await createPurpose(data.value)
        }
      } catch (err) {
        console.log(err.response)
      }
      
      
      context.emit('update', data.value);
    };

    onMounted(() => {
      data.value = cloneDeep(props.modelValue);
    })

    return {
      data,
      errors,
      onUpdate,
      onClose
    }
  }
})
