<template>
  <div class="text-gray-900 inline-block align-bottom rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:align-middle sm:max-w-lg sm:w-full" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
    <div class="bg-white px-6  pb-4 sm:p-6 sm:pb-4">
      <form
        @submit.prevent="onUpdate"
        v-if="data != null"
        class="pt-6"
      >
        <text-field
          v-model="data.name"
          label="検討内容"
          class="mb-5"
          :error="errors.name"
        ></text-field>
        <text-field
          v-model="data.sort"
          label="ソート"
          class="mb-5"
          :error="errors.sort"
        ></text-field>
        <text-field
          v-model="data.bgcolor"
          label="背景色"
          class="mb-5"
          :error="errors.bgcolor"
        ></text-field>
        <text-field
          v-model="data.fontcolor"
          label="文字色"
          class="mb-5"
          :error="errors.fontcolor"
        ></text-field>
        <select-box
          :options="userList.map(u => ({ value: u.id, text: u.email }))"
          v-model="data.approved_by_id"
          label="承認者"
          class="mb-5"
          :error="errors.approved_by_id"
        ></select-box>
       
        <div class="text-left">
          <span class="text-sm ml-2">表示</span>
          <my-toggle
          v-model="data.display"
          ></my-toggle>
            
        </div>
        <div>
          <button class="success" @click="update">
            {{ data.id ? '更新' : '追加' }}
          </button>
          <button class="ml-5" @click="onClose">
            閉じる
          </button>
        </div>
      </form>
    </div>
  </div>
</template>
<script lang="ts">
import { computed, defineComponent, onMounted, PropType, ref, SetupContext, } from 'vue';
import { IProposalStatus, IPurpose, IUser } from '@/types/Interfaces';
import { cloneDeep, create, update } from 'lodash';
import usePurpose from '@/types/Purpose'
import { formatRelative } from 'date-fns';
import { validate, required, isNumber, isColor } from '@/mixins/Validators';
import SelectBox from '@/components/SelectBox.vue';

export default defineComponent({
  components: {
    SelectBox
  },
  props: {
    modelValue: {
      type: Object as () => IProposalStatus
    },
    userList: {
      type: Object as () => IUser[]
    }
  },
  setup(props: any, context: SetupContext) {
    
    const data = ref<IPurpose | null>(null);
    const {
      createPurpose,
      updatePurpose
    } = usePurpose()

    
    const errors = ref({
      name: '',
      sort: '',
      bgcolor: '',
      fontcolor: '',
      approved_by_id: ''
    })
    const validators = {
      name: [
        required
      ],
      sort: [
        required,
        isNumber
      ],
      bgcolor: [
        required,
        isColor
      ],
      fontcolor: [
        required,
        isColor
      ],
      approved_by_id: [
        required,
      ],
   
    }
    
    const onClose = () => {
      context.emit('close');  
    };
    const onUpdate = async () => {
      // do some async work
    
      if (data.value == null) return;
      console.log(data)
      if (!validate(data, validators, errors)) return;
      let r;
      try {
        if (data.value.id) {
          r = await updatePurpose(data.value.id, data.value)
        } else {
          r = await createPurpose(data.value)
        }
      } catch (err) {
        console.log(err.response)
      }
      
      
      context.emit('update', data.value);
    };

    onMounted(() => {
      data.value = cloneDeep(props.modelValue);
    })

    return {
      data,
      errors,
      onUpdate,
      onClose
    }
  }
})
</script>
